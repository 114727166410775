import React, {Component} from 'react';
import {Button, FormHelperText, InputLabel, TextField, Typography} from '@material-ui/core';
import styles from './ApplyNowForm.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import {emailRegEx} from "../../constants/regex";


class ApplyNowForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      email: '',
      phone: '',
      message: '',
      filename: null,
      isEmailFieldDirty: false,
      isNameFieldDirty: false,
      isSubmitting: false,
      emailError: 'Please enter a valid email.',
      nameError: 'Please enter your name.',
      phoneLessThan10: null,
      phoneMoreThan15: null,
      formSubmitted: null
    };
    this.hubspotInputs = {};
    this.form = null;
    this.hubspotForm = null;
    this.hubspotSubmit = null;
  }

  componentDidMount() {
    document.addEventListener('submit', this.onFormSubmit);
    this.form = document.getElementById(`form-${this.props.jobId}`);
    const script = document.createElement('script');
    const scriptText = document.createTextNode('setTimeout(() => window.hbspt && hbspt.forms.create({region: "na1", portalId: "8031920",formId: "79b27767-3f08-4da6-8366-45980840aab6"}), 0)')
    script.appendChild(scriptText);
    this.form.appendChild(script);
    setTimeout(() => {
      try {
        this.hubspotForm = this.form.querySelector('iframe');
        const copyright = this.hubspotForm.contentDocument.querySelector('.hubspot-link__container');
        if (copyright) {
          copyright.remove();
        }
        const submitButton = this.hubspotForm.contentDocument.querySelector('input[type=submit]');
        if (submitButton) {
          submitButton.setAttribute('value', '');
          submitButton.style.backgroundColor = 'transparent';
          submitButton.style.border = 'none';
          submitButton.style.height = '1px';
          submitButton.style.padding = '0';
          submitButton.style.width = '1px';
          this.hubspotSubmit = submitButton;
        }
        const inputNames = ['firstname', 'email', 'phone', 'message', 'cv_attachment', 'website'];
        inputNames.forEach(name => this.hubspotInputs[name] = this.hubspotForm.contentWindow.document.querySelector(`input[name=${name}]`));
        this.hubspotForm.contentDocument.addEventListener('submit', this.onHubspotFormSubmit);
        this.hubspotInputs['cv_attachment'].type = 'file';
        this.hubspotInputs['cv_attachment'].addEventListener('change', this.onFileInputChange);
        if (window) {
          const indexOfHash = window.location.href.indexOf('#');
          const location = indexOfHash !== -1 ? window.location.href.slice(0, indexOfHash) : window.location.href;
          this.hubspotInputs['website'].value = `${location}#${this.props.jobId}`;
        }
      } catch (e) {
        console.log('error', e);
      }
    }, 1000);
  }

  componentWillUnmount() {
    document.removeEventListener('submit', this.onFormSubmit);
    if (this.hubspotForm) {
      this.hubspotForm.contentWindow.document.removeEventListener('submit', this.onHubspotFormSubmit);
    }
    if (this.hubspotInputs['cv_attachment']) {
      this.hubspotInputs['cv_attachment'].removeEventListener('change', this.onFileInputChange);
    }
  }

  checkEmailValidity = ({target}) => {
    const {value} = target;
    this.setState({emailError: null});
    if (!emailRegEx.test(value)) {
      this.setState({emailError: 'Please enter a valid email.'})
    }
  };

  checkNameValidity = ({target}) => {
    const {value} = target;
    this.setState({nameError: null});
    if (!value || !value.trim()) {
      this.setState({nameError: 'Please enter your name.'})
    }
  };

  checkPhoneValidity = ({target}) => {
    const {value} = target;
    this.setState({phoneLessThan10: null, phoneMoreThan15: null});
    if (!value) {
      return;
    }
    if (value.length < 10) {
      this.setState({phoneLessThan10: 'Please enter a phone number that\'s at least 10 numbers long.'})
    }
    if (value.length > 15) {
      this.setState({phoneMoreThan15: 'Please enter a phone number that\'s at most 15 numbers long.'})
    }
  };

  onFileInputChange = () => {
    const value = this.hubspotInputs['cv_attachment'].value;
    const indexOfSlash = value.lastIndexOf('\\');
    const filename = value.slice(indexOfSlash + 1);
    this.setState({filename})
  };

  onChange = ({target}) => {
    const {name, value} = target;
    this.hubspotInputs[name].value = value;
    if (name === 'firstname') {
      this.setState({
        isNameFieldDirty: true
      });
    }
    if (name === 'email') {
      this.setState({
        isEmailFieldDirty: true
      });
    }
  };

  onEmailChange = event => {
    this.onChange(event);
    this.checkEmailValidity(event);
  };

  onNameChange = event => {
    this.onChange(event);
    this.checkNameValidity(event);
  };

  onPhoneChange = event => {
    this.onChange(event);
    this.checkPhoneValidity(event);
  };

  submit = () => {
    const {emailError, isSubmitting, nameError, phoneLessThan10, phoneMoreThan15} = this.state;
    if (emailError || isSubmitting || nameError || phoneLessThan10 || phoneMoreThan15) {
      return;
    }
    if (this.hubspotSubmit) {
      this.setState({
        isSubmitting: true
      });
      this.hubspotSubmit.click();
    }
  };

  onFormSubmit = event => event.preventDefault();

  onHubspotFormSubmit = () => {
    this.setState({
      isSubmitting: false
    });
    this.props.onFormSubmission();
  };

  selectFile = () => {
    this.hubspotInputs['cv_attachment'].click();
  };

  unselectFile = () => {
    this.hubspotInputs['cv_attachment'].value = null;
    this.setState({filename: null})
  };

  render() {
    const {jobId} = this.props;
    const {
      filename, isEmailFieldDirty, isNameFieldDirty, isSubmitting, emailError, nameError, phoneLessThan10,
      phoneMoreThan15
    } = this.state;
    const phoneError = phoneLessThan10 || phoneMoreThan15;
    const isEmailError = isEmailFieldDirty && !!emailError;
    const isNameError = isNameFieldDirty && !!nameError;
    return (
      <form id={`form-${jobId}`} className={styles['form']}>
        <div className={styles['form__container']}>
          <div className={styles['form__container-part']}>
            {isNameError && <FormHelperText error>{nameError}</FormHelperText>}
            <TextField
              className={styles['form__right-input']}
              error={isNameError}
              label={isNameError ? '' : 'Name'}
              name={'firstname'}
              onChange={this.onNameChange}
              required
            />
            {isEmailError && <FormHelperText error>{emailError}</FormHelperText>}
            <TextField
              className={styles['form__right-input']}
              error={isEmailError}
              label={isEmailError ? '' : 'Email'}
              name={'email'}
              onChange={this.onEmailChange}
              required
              type={'email'}
            />
            {phoneError && <FormHelperText error>{phoneError}</FormHelperText>}
            <TextField
              className={styles['form__right-input']}
              error={!!phoneError}
              label={phoneError ? '': 'Phone'}
              maxLength={15}
              minLength={10}
              name={'phone'}
              onChange={this.onPhoneChange}
              type={'tel'}
            />
          </div>
          <div className={styles['form__container-part']}>
            <TextField
              className={styles['form__left-input']}
              label={'Message'}
              multiline
              name={'message'}
              onChange={this.onChange}
              rowsMax={5}
            />
            <InputLabel shrink={!!filename}>CV</InputLabel>
            <div className={styles['form__upload-file-container']}>
              <Typography
                className={filename ? styles['file-uploaded']: ''}
                color={'secondary'}
                onClick={this.selectFile}
              >
                {filename ? filename : 'Upload file'}
              </Typography>
              {filename && <CloseIcon onClick={this.unselectFile}/>}
            </div>
          </div>
        </div>
        <div className={styles['form__submit-button-container']}>
          <Button
            color="secondary"
            disabled={!!emailError || isSubmitting || !!nameError || !!phoneError}
            onClick={this.submit}
            variant="contained"
          >
            Submit application
          </Button>
        </div>
      </form>
      );
  }
}

export default ApplyNowForm
