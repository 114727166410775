import Section from "../Section";
import React from "react";
import styles from './Careers.module.scss';
import InnerPagesUpperBanner from "../InnerPagesUpperBanner";
import {graphql, useStaticQuery} from "gatsby";
import Typography from "@material-ui/core/Typography";
import {
  CURRENT_OPENINGS_TITLE,
  MAIN_TITLE,
  MODERN_CAREERS_BTN_TEXT,
  MODERN_CAREERS_TEXT,
  MODERN_CAREERS_TITLE
} from "../../constants/careers";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Button from "@material-ui/core/Button";
import Vacancy from "../Vacancy/Vacancy";

const Careers = ({vacancies}) => {
  const {background} = useStaticQuery(graphql`
    query {
      background: allFile(
        filter: {
          sourceInstanceName: { eq: "careers" }
          name: { eq: "bg" }
        }
      ){
        nodes {
          name
          publicURL
        }
      }
      plus: allFile(
        filter: {
          sourceInstanceName: { eq: "otherImages" }
          name: { eq: "plus" }
        }
      ){
        nodes {
          name
          publicURL
        }
      }
      minus: allFile(
        filter: {
          sourceInstanceName: { eq: "otherImages" }
          name: { eq: "minus" }
        }
      ){
        nodes {
          name
          publicURL
        }
      }
    }
  `)
  const vacanciesWithId = vacancies.map(v => {
    return {...v, newId:  v.node.id.slice(0, v.node.id.indexOf('-'))};
  });

  return (
    <Section backgroundColor="#fff" height={'auto'}>
      <div className={styles.careers}>
        <InnerPagesUpperBanner
          text={[MAIN_TITLE]}
          bg={background.nodes[0].publicURL}
        />
        <div className={styles['container']}>
          <Typography
            variant={'h2'}
            className={styles['careers__title']}
            align={'center'}
            color={'secondary'}>
            {MODERN_CAREERS_TITLE}
          </Typography>
          <div className={styles['careers__modern-careers']}>
            {MODERN_CAREERS_TEXT.map((line, i) => (
              <Typography key={i} className={styles['careers__career-line']}>{line}</Typography>))}
            <Button
              variant="contained"
              endIcon={<ArrowForward/>}
              color="secondary"
              className={styles['careers__btn']}
              href={'/company'}
            >
              {MODERN_CAREERS_BTN_TEXT}
            </Button>
          </div>
        </div>
        <div className={styles['careers__current-openings']}>
          <div className={styles['container']}>
            <Typography
              variant={'h2'}
              align={'center'}
              className={styles['careers__title']}
              color={'secondary'}>{CURRENT_OPENINGS_TITLE}</Typography>
            {vacanciesWithId.map(v => {
              const slugHasDetailsText = v.node.fields.slug.includes(':');
              const [placeOfWork, time] = v.node.fields.slug.replace('/pages/', '').split(':');
              return (
                <Vacancy key={v.newId}
                         placeOfWork={slugHasDetailsText ? placeOfWork : null}
                         time={slugHasDetailsText ? time : null}
                         jobId={v.newId}
                         jobTitle={v.node.frontmatter.title}
                         jobHtml={v.node.html}/>
              )
            })}
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Careers
