import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import Careers from "../components/Careers";
import {PAGE_DESCRIPTION} from "../constants/careers";

const CareersTemplate = ({data, location}) => {
  return (
    <Layout title="CyberVision - Careers"
            location={location}
            description={PAGE_DESCRIPTION}
            socialImage={data.allFile.nodes[0].publicURL}
    >
      <Careers vacancies={data.allMarkdownRemark.edges}/>
    </Layout>
  );
}

export const query = graphql`
  query Careers {
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "page" }, draft: { ne: true } } }
      sort: { order: [ASC, DESC], fields: [frontmatter___title, frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          id
          frontmatter {
            title
            socialImage
          }
        }
      }
    }
    allFile(filter: {
      sourceInstanceName: { eq: "preview" }
      name: { eq: "careers" }
    }){
      nodes {
        name
        publicURL
      }
    }
  }
`

export default CareersTemplate
