export const MAIN_TITLE = 'Careers';
export const MODERN_CAREERS_TITLE = 'Build your modern IT career with us!';
export const MODERN_CAREERS_TEXT = [
  'CyberVision is a software development and integration company, Google Cloud Partner, and HP partner with R&D facilities in the US and Eastern Europe. Over more than two decades in the high-tech business, CyberVision has designed and implemented a large number of innovative solutions that granted a proven competitive edge to our clients and partners.',
'Our major specializations include Big Data, ML & AI, IoT development, DevOps services, Blockchain implementation, robotics, and embedded applications. We also offer full-scale system support services.',
  'If leading-edge technologies and Western business culture are what you’ve been looking for, be sure to apply for our open positions.',
  'At Cybervision you will have the opportunity to gain experience and work on interesting and challenging projects.'
];
export const MODERN_CAREERS_BTN_TEXT = 'More about us';
export const APPLY_NOW_BTN_TEXT = 'Apply now';
export const CURRENT_OPENINGS_TITLE = 'Current openings';
export const PLACE_OF_WORK = 'Kyiv/Remote';
export const TIME = 'Full Time';
export const PAGE_DESCRIPTION = 'Build your modern IT career with CyberVision, a software development and integration company with vast experience in Big Data, ML & AI, IoT, DevOps, Blockchain, robotics, and embedded applications.';
