import React, {useState} from 'react';
import Typography from "@material-ui/core/Typography";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import {APPLY_NOW_BTN_TEXT, PLACE_OF_WORK, TIME} from "../../constants/careers";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import Button from "@material-ui/core/Button";
import ApplyNowForm from "../ApplyNowForm/ApplyNowForm";
import ExpandingCard from "../ExpandingCard";
import styles from './Vacancy.module.scss';
import {graphql, useStaticQuery} from "gatsby";
import FormSubmittedNotification from "../FormSubmittedNotification/FormSubmittedNotification";

export const Vacancy = ({placeOfWork, time, jobId, jobTitle, jobHtml}) => {
  const [applyNowIsOpen, setApplyNowIsOpen] = useState(false);
  const [formIsSubmitted, setFormIsSubmitted] = useState(false);
  const {plus, minus} = useStaticQuery(graphql`
    query {
      plus: allFile(
        filter: {
          sourceInstanceName: { eq: "otherImages" }
          name: { eq: "plus" }
        }
      ){
        nodes {
          name
          publicURL
        }
      }
      minus: allFile(
        filter: {
          sourceInstanceName: { eq: "otherImages" }
          name: { eq: "minus" }
        }
      ){
        nodes {
          name
          publicURL
        }
      }
    }
  `)
  return (<ExpandingCard key={jobId}
                         expander={plus.nodes[0].publicURL}
                         collapser={minus.nodes[0].publicURL}
                         custom
                         hasLink
                         cardId={jobId}
  >
    {{
      collapsedView: (
        <>
          <Typography
            id={jobId}
            color={'secondary'}
            variant={'h4'}
            className={styles['vacancy__job-title']}
          >{jobTitle}</Typography>
        </>
      ),
      expandedView: (
        <>
          <div className={styles['vacancy__details']}>
            <LocationOnOutlinedIcon/>
            <Typography variant={'body2'}>{placeOfWork || PLACE_OF_WORK}</Typography>
            <AccessTimeIcon/>
            <Typography variant={'body2'}>{time || TIME}</Typography>
          </div>
          <div className={styles['vacancy__description']}
               dangerouslySetInnerHTML={{__html: jobHtml}}/>
          {!applyNowIsOpen && !formIsSubmitted && <Button
            variant="contained"
            color="secondary"
            onClick={() => setApplyNowIsOpen(true)}
            className={styles['vacancy__apply-btn']}
          >
            {APPLY_NOW_BTN_TEXT}
          </Button>}
          {applyNowIsOpen && <div className={`${styles['vacancy__apply-container']} ${formIsSubmitted && styles['hide']}`}>
            <div>You can send your CV to <a href="mailto:careers@cybervisiontech.com">careers@cybervisiontech.com</a></div>
          </div>}
        </>
      )
    }}
  </ExpandingCard>)
}

export default Vacancy
